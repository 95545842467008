<template>
    <AADHumToday/>
    <Nav />
    <Footer />
</template>

<script>
    // @ is an alias to /src

    import AADHumToday from "@/components/AADHumToday";
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";

    export default {
        name: 'Home',
        components: {Footer, Nav, AADHumToday}
    }
</script>

<style scoped lang="scss">
    .top {
        color: white;
        background-color: black;
        margin: 0;
        font-size: 18px;
        height: 2vh;
    }

</style>
