<template>
    <router-view/>
</template>

<style lang="scss">
    @import url("https://use.typekit.net/kqq8spf.css");

    @font-face {
        font-family: freight-text-pro, serif;
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: freight-sans-pro, sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: Alioli;
        src: url('assets/Alioli-Regular.woff');
        font-style: normal;
    }
	@font-face {
		font-family: DuBois-B;
		src: url('assets/VTCDuBois-Bold.woff2');
		font-style: normal;
	}

	@font-face {
		font-family: Grotesk;
		src: url('assets/Grotesk.otf');
		font-style: normal;
	}

	@font-face {
		font-family: hngl;
		src: url('assets/vtf_hngl-webfont.ttf');
		font-style: normal;
	}

	@font-face {
		font-family: Marker;
		src: url('assets/PermanentMarker-Regular.ttf');
		font-style: normal;
	}

	@font-face {
		font-family: Spartan-Medium;
		src: url('assets/spartan-cufonfonts/Spartan-Medium.ttf');
		font-style: normal;
	}

	@font-face {
		font-family: Bayard;
		src: url('assets/Bayard-Regular.woff2');
		font-style: normal;
	}

    @font-face {
        font-family: Avera;
        src: url('assets/AveraSansTC-Lt.otf') format('otf');
        font-style: normal;
    }

    @font-face {
        font-family: 'Avera Sans TC';
        src: url('assets/AveraSansTC-Regular.woff2') format('woff2'),
        url('assets/AveraSansTC-Regular.woff') format('woff'),
        url('assets/AveraSansTC-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
	
    html {
        scroll-behavior: smooth;
    }

    body {
        margin: 0;
    }

    h1 {
        font-size: 42px;
    }

    h2 {
        font-size: 36px;
    }

    h3 {
        font-size: 28px;
    }

    p {
        font-size: 24px;
        font-family: "freight-text-pro", serif;
        line-height: 1.25;
    }

    a {
        text-decoration: none;
        color: #cf5c5c;
    }
    
    iframe {
        border: none;
    }

    #app {
        font-family: 'freight-sans-pro', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        max-width: 100vw;
    }

    .learnMore {
        color: #de8715;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 20px;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-enter-active, .modal-leave-active {
        opacity: 0;
        transition: opacity .75s;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .vueperslide__title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 12px;
    }

    .vueperslide__content {
        font-size: 18px;
        text-align: left;
        margin: 0 40px;
    }
</style>
