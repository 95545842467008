<template>
    <div id="Programs">
<!--        <div class="currentCalls">-->
<!--            <div class="calls">-->
<!--                <h2>Current Application Periods</h2>-->
<!--&lt;!&ndash;                <p>We don't currently have any ongoing applications! Check back later :)</p>&ndash;&gt;-->
<!--&lt;!&ndash;                <p>AADHum Scholars Program — Due 6/5/22 | <a href="https://aadhum.notion.site/The-Graduate-AADHum-Scholars-Program-b7d7d4d5fd274f0d884f0bf909244858" target="_blank">Learn More</a></p>&ndash;&gt;-->
<!--&lt;!&ndash;                <p>Graduate AADHum Scholars Program — Due 6/5/22 | <a href="https://aadhum.notion.site/The-Graduate-AADHum-Scholars-Program-b7d7d4d5fd274f0d884f0bf909244858" target="_blank">Learn More</a></p>&ndash;&gt;-->
<!--                <p><b>AADHum Microgrants Program</b> — Rolling Submissions, deadline on the 1st of each month</p>-->
<!--            </div>-->
<!--        </div>-->
		<div class="filterGroups">
			<h2>UMD System & Local HBCUs</h2>
			<div class="umdFilter">
				<h3 id="facultyBtn" class="umd filterButton" v-on:click="filterSelection('umdFaculty')">Faculty</h3>
				<h3 id="gradBtn" class="umd filterButton" v-on:click="filterSelection('umdGrad')">Graduate Students</h3>
				<h3 id="undergradBtn" class="umd filterButton" v-on:click="filterSelection('umdUndergrad')">Undergraduate Students</h3>
			</div>
			<h2>Everyone</h2>
			<div class="externalFilter">
				<h3 id="facultyBtn2" class="ev filterButton" v-on:click="filterSelection('f2aculty')">Faculty</h3>
				<h3 id="indieBtn" class="ev filterButton" v-on:click="filterSelection('indie')">Independent Scholars & Artists</h3>
				<h3 id="gradBtn2" class="ev filterButton" v-on:click="filterSelection('g2rad')">Graduate Students</h3>
				<h3 id="undergradBtn2" class="ev filterButton" v-on:click="filterSelection('u2ndergrad')">Undergraduate Students</h3>
			</div>
            <div class="allFilter">
                <h3 id="allBtn" class="filterButton" v-on:click="filterSelection('all')">Remove Filter</h3>
            </div>
		</div>
		<div class="grid">
			<div v-for="program in programs" :key="program.order" class="programBox programFilter show" :class="program.filter" @click="currentProgram = program; showModal = true">
				<div>
					<img :src="program.image" :alt="program.name" class="featuredImage">
					<h2 class="title">{{ program.name }}</h2>
				</div>
			</div>
		</div>
		<transition name="modal">
			<Program :program="currentProgram" v-if="showModal" v-on:closeModal="showModal=false"/>
		</transition>
    </div>
</template>

<script>
    import Program from "../Programs/Program";
	export default {
        name: "Programs",
		components: {Program},
		data() {
			return {
				programs: [],
				showModal: false,
				currentProgram: null
			}
		},
		created() {
			const request = new XMLHttpRequest();
			request.open("GET", "AADHumPrograms.json", false);
			request.send(null)
			this.programs = (JSON.parse(request.responseText));
			
			this.programs = this.programs.sort((a, b) => {
				let fa = a.order,
					fb = b.order
				if (fa < fb) {
					return -1;
				}
				if (fa > fb) {
					return 1;
				}
				return 0;
			})
		},
		methods: {
			filterSelection(c) {
				let x, i;
				x = document.getElementsByClassName("programFilter");
				
				const allBtn = document.getElementById('allBtn')
				const facultyBtn = document.getElementById('facultyBtn')
				const gradBtn = document.getElementById('gradBtn')
				const undergradBtn = document.getElementById('undergradBtn')
				const facultyBtn2 = document.getElementById('facultyBtn2')
				const indieBtn = document.getElementById('indieBtn')
				const gradBtn2 = document.getElementById('gradBtn2')
				const undergradBtn2 = document.getElementById('undergradBtn2')
				
				if (c === 'umdFaculty') {
					allBtn.classList.remove('activeBtn')
					facultyBtn.classList.add('activeBtn')
					gradBtn.classList.remove('activeBtn')
					undergradBtn.classList.remove('activeBtn')
					facultyBtn2.classList.remove('activeBtn')
					indieBtn.classList.remove('activeBtn')
					gradBtn2.classList.remove('activeBtn')
					undergradBtn2.classList.remove('activeBtn')
				} else if (c === 'umdGrad') {
					allBtn.classList.remove('activeBtn')
					facultyBtn.classList.remove('activeBtn')
					gradBtn.classList.add('activeBtn')
					undergradBtn.classList.remove('activeBtn')
					facultyBtn2.classList.remove('activeBtn')
					indieBtn.classList.remove('activeBtn')
					gradBtn2.classList.remove('activeBtn')
					undergradBtn2.classList.remove('activeBtn')
				} else if (c === 'umdUndergrad') {
					allBtn.classList.remove('activeBtn')
					facultyBtn.classList.remove('activeBtn')
					gradBtn.classList.remove('activeBtn')
					undergradBtn.classList.add('activeBtn')
					facultyBtn2.classList.remove('activeBtn')
					indieBtn.classList.remove('activeBtn')
					gradBtn2.classList.remove('activeBtn')
					undergradBtn2.classList.remove('activeBtn')
				} else if (c === 'f2aculty') {
					allBtn.classList.remove('activeBtn')
					facultyBtn.classList.remove('activeBtn')
					gradBtn.classList.remove('activeBtn')
					undergradBtn.classList.remove('activeBtn')
					facultyBtn2.classList.add('activeBtn')
					indieBtn.classList.remove('activeBtn')
					gradBtn2.classList.remove('activeBtn')
					undergradBtn2.classList.remove('activeBtn')
				} else if (c === 'indie') {
					allBtn.classList.remove('activeBtn')
					facultyBtn.classList.remove('activeBtn')
					gradBtn.classList.remove('activeBtn')
					undergradBtn.classList.remove('activeBtn')
					facultyBtn2.classList.remove('activeBtn')
					indieBtn.classList.add('activeBtn')
					gradBtn2.classList.remove('activeBtn')
					undergradBtn2.classList.remove('activeBtn')
				} else if (c === 'g2rad') {
					allBtn.classList.remove('activeBtn')
					facultyBtn.classList.remove('activeBtn')
					gradBtn.classList.remove('activeBtn')
					undergradBtn.classList.remove('activeBtn')
					facultyBtn2.classList.remove('activeBtn')
					indieBtn.classList.remove('activeBtn')
					gradBtn2.classList.add('activeBtn')
					undergradBtn2.classList.remove('activeBtn')
				} else if (c === 'u2ndergrad') {
					allBtn.classList.remove('activeBtn')
					facultyBtn.classList.remove('activeBtn')
					gradBtn.classList.remove('activeBtn')
					undergradBtn.classList.remove('activeBtn')
					facultyBtn2.classList.remove('activeBtn')
					indieBtn.classList.remove('activeBtn')
					gradBtn2.classList.remove('activeBtn')
					undergradBtn2.classList.add('activeBtn')
				} else if (c === 'all') {
					allBtn.classList.add('activeBtn')
					facultyBtn.classList.remove('activeBtn')
					gradBtn.classList.remove('activeBtn')
					undergradBtn.classList.remove('activeBtn')
					facultyBtn2.classList.remove('activeBtn')
					indieBtn.classList.remove('activeBtn')
					gradBtn2.classList.remove('activeBtn')
					undergradBtn2.classList.remove('activeBtn')
				}
				
				if (c === "all") c = "";
				
				for (i = 0; i < x.length; i++) {
					this.removeClass(x[i], "show");
					if (x[i].className.indexOf(c) > -1) {
						this.addClass(x[i], "show");
					}
				}
			},
			addClass(element, name) {
				let i, arr1, arr2;
				arr1 = element.className.split(" ");
				arr2 = name.split(" ");
				for (i = 0; i < arr2.length; i++) {
					if (arr1.indexOf(arr2[i]) === -1) {
						element.className += " " + arr2[i];
					}
				}
			},
			removeClass(element, name) {
				let i, arr1, arr2;
				arr1 = element.className.split(" ");
				arr2 = name.split(" ");
				for (i = 0; i < arr2.length; i++) {
					while (arr1.indexOf(arr2[i]) > -1) {
						arr1.splice(arr1.indexOf(arr2[i]), 1);
					}
				}
				element.className = arr1.join(" ");
			}
		},
    }
</script>

<style scoped lang="scss">
    #Programs {
        max-width: 1400px;
        margin: 0 auto;
    }

    h2 {
        margin-bottom: 0;
    }
	
	.grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-gap: 40px;
        @media only screen and (max-width: 800px) {
            justify-content: center;
        }
	}
    
    .title {
        margin: 0;
        font-size: 28px;
        font-weight: normal;
        transition: 0.75s;
        opacity: 0;
        max-width: 350px;
    }
	
	.programBox {
		transition: 0.75s;
		box-shadow: 8px 6px 10px rgba(67, 62, 62, 0);
		display: none;
		cursor: pointer;
		&.show {
			display: flex;
		}
		&:hover .featuredImage {
			transition: 0.75s;
			box-shadow: 0 0 35px #0bac86;
		}
		&:hover .title {
			transition: 0.75s;
			opacity: 100%;
		}
	}

	.featuredImage {
		width: 350px;
		border-radius: 12px;
		transition: 0.75s;
	}

    .learnMore {
        font-family: 'freight-sans-pro', sans-serif;
        margin: 0;
    }

	.umdFilter, .externalFilter {
		display: flex;
		align-items: center;
        grid-gap: 12px;
        
        @media only screen and (max-width: 1000px) {
			flex-direction: column;
            align-items: flex-start;
		}
	
		.filterButton {
			cursor: pointer;
			font-weight: normal;
			font-size: 28px;
			color: #8f8f8f;
			border: 2px solid #8f8f8f;
			padding: 6px 8px 3px 8px;
			transition: 0.25s;
   
			&:hover {
				border: 2px solid #232323;
				transition: 0.25s;
			}
            @media only screen and (max-width: 1500px) {
                font-size: 24px;
            }
			@media only screen and (max-width: 800px) {
				padding: 8px;
    
			}
		}
	
		.activeBtn {
			color: black;
			border: 2px solid black;
		}
		.umd.activeBtn {
			color: black;
			border: 2px solid #e73131;
		}
		.ev.activeBtn {
			color: black;
			border: 2px solid #22b4c3;
		}
	}
	
	.filterGroups {
		padding-bottom: 40px;
		h2, h3 {
            font-size: 26px;
			margin: 0 0 5px 0;

		}
		.allFilter {
            margin-top: 40px;
			.filterButton {
				cursor: pointer;
				font-weight: normal;
                display: inline;
                background-color: #eaeaea;
                padding: 5px 10px;
                border-radius: 20px;
                font-size: 20px;
                &:hover {
					color: #cf5c5c;
				}
			}
		}
		.umdFilter {
			border-left: #e73131 10px solid;
			margin-left: 5px;
			padding-left: 10px;
			margin-bottom: 20px;
		}
		.externalFilter {
			border-left: #22b4c3 10px solid;
			margin-left: 5px;
			padding-left: 10px;
		}
	}
    
    .currentCalls {
        background-color: rgba(243, 179, 103, 0.25);
        margin: 20px 20px 60px 20px;
        .calls {
            h2 {
                margin: 0;
                font-size: 26px;
            }
            p {
                margin: 0;
            }
            a {
                color: #22b4c3;
                &:hover {
                    color: #cf5c5c;
                }
            }
            padding: 20px 40px;
        }
    }

    .filterGroups h2, .umdFilter h3, .externalFilter h3 {
        font-family: Avera Sans TC, sans-serif;
        font-weight: normal;
    }
    
</style>