<template>
    <div class="navContainer">
        <div class="empty">
        </div>
        <div id="nav">
            <a id="newstab" class="tab-button" href="news" target="_blank">
                <span>News</span>
            </a>
            <a v-for="tab in tabs" :key="tab" :id="tab.component.name" :class="['tab-button', { active: currentTab === tab.component }]" @click="currentTab = tab.component; currentDescription = tab.description" :href="'#' + tab.component.name" >
                <span>{{ tab.component.name }}</span>
            </a>
        </div>
    </div>
    <div class="navContainerMobile">
        <div class="mobileMenuIcon" @click="mobileToggle">
            <div id="hamburgerIcon" class="container" onclick="this.classList.toggle('active')">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 200 200">
                    <g stroke-width="6.5" stroke-linecap="round">
                        <path
                            d="M72 82.286h28.75"
                            fill="#009100"
                            fill-rule="evenodd"
                            stroke="#fff"
                        />
                        <path
                            d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
                            fill="none"
                            stroke="#fff"
                        />
                        <path
                            d="M72 125.143h28.75"
                            fill="#009100"
                            fill-rule="evenodd"
                            stroke="#fff"
                        />
                        <path
                            d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
                            fill="none"
                            stroke="#fff"
                        />
                        <path
                            d="M100.75 82.286h28.75"
                            fill="#009100"
                            fill-rule="evenodd"
                            stroke="#fff"
                        />
                        <path
                            d="M100.75 125.143h28.75"
                            fill="#009100"
                            fill-rule="evenodd"
                            stroke="#fff"
                        />
                    </g>
                </svg>
            </div>
        </div>
        <div id="mobileDisplay" class="navLinksMobile">
            <a id="newstab" class="tab-button" href="news" target="_blank">
                <span>News</span>
            </a>
            <a v-for="tab in tabs" :key="tab" :id="tab.component.name" :class="['tab-button', { active: currentTab === tab.component }]" @click="currentTab = tab.component; currentDescription = tab.description; closeMenu" :href="'#' + tab.component.name" >
                <span>{{ tab.component.name }}</span>
            </a>
        </div>
    </div>
    <div class="contentContainer">
        <div class="noMobile">
            <Sidebar :foo="currentDescription" class="stickySide"/>
        </div>
        <div>
            <component :is="currentTabComponent" class="tab"></component>
        </div>
    </div>
</template>

<script>
    import About from "@/components/Tabs/About";
    import Team from "@/components/Tabs/Team";
    import Programs from "@/components/Tabs/Programs";
    import Research from "@/components/Tabs/Research";
    import Community from "@/components/Tabs/Community";
    import Sidebar from "@/components/Sidebar";
    // import Courses from "./Tabs/Courses.vue";

    export default {
        name: "Nav",
        components: {Sidebar},
        data() {
            return {
                currentTab: About,
                tabs: {
                    About: {
						component: About,
						description: {
                            title: "About",
                            description: "The African American Digital and Experimental Humanities (AADHum) Initiative is a humanities research and design unit dedicated to experimental digital research that connects work about Black people to larger Black intellectual traditions. Funded by the Andrew W. Mellon foundation and housed at the Maryland Institute for Technology in the Humanities (MITH) at the University of Maryland, AADHum focuses both on critical digital humanities scholarship on the histories and impacts of digital technologies on Black lifeworlds, as well as on the ideation and production of projects that highlight how Black life bears on such technologies."
                        }
					},
					Research: {
						component: Research,
						description: {
                            title: "Research",
                            description: "AADHum continues to deepen its commitments to broadening the landscape of Black digital humanities, while also highlighting the numerous places where Black DH intersects with electronic literature, digital storytelling, computational poetics, and public humanities more generally. Through our own boundary-pushing research collaborations with leading scholars in the arts and humanities, AADHum serves as a vibrant center for Black DH research both in the greater Washington, DC area as well as nationally and internationally. We foster research at all levels, from grantmaking and fellowship opportunities for undergraduate students, to residencies for senior scholars and collaborators, to community engagement programs that redefine the sites of Black DH research in a changing educational landscape."
                        }
					},
					Team: {
						component: Team,
						description: {
                            title: "Team",
                            description: "AADHum’s work is organized and executed by a core team of faculty, staff, and students, both at the graduate and undergraduate level. We are part of University of Maryland’s School of Arts and Humanities (ARHU), and are also part of the Maryland Institute for Technology in the Humanities (MITH)!"
                        }
					},
                    Community: {
                        component: Community,
                        description: {
                            title: "Community",
                            description: "The work of AADHum is mainly expressed through digital and experimental projects, as well as through other kinds of creative and scholarly interventions. At the core of our work, however, are the people who come to us and whom we seek out— for ideas, for inspiration, for pathways, for seedbeds, for community. "
                        }
                    },
                    Programs: {
                        component: Programs,
                        description: {
                            title: "Programs",
                            description: "Our numerous programs reflect our perspective on supporting communities of digital and experimental scholarly practice.  Each program is designed to support idea-holders at different points in their scholarly journey. In addition to our programs that enable us to collaborate with different segments of our communities, we offer programs that center humanities design thinking. We also feature ???Shops and Open Labs that on-ramp scholars and creatives interested in what’s possible at the intersection of Black Study and digital or experimental work,  as well as helping local practictioners build their own communities of practice in our “kitchen studios.” \n"
                        }
                    },
                    // Courses: {
                    //     component: Courses,
                    //     description: {
                    //         title: "Courses",
                    //         description: "hello hello, we teach!!"
                    //     }
                    // }
				},
				currentDescription: {
                    title: "About",
                    description: "The African American Digital and Experimental Humanities (AADHum) Initiative is a humanities research and design unit dedicated to experimental digital research that connects work about Black people to larger Black intellectual traditions. Funded by the Andrew W. Mellon foundation and housed at the Maryland Institute for Technology in the Humanities (MITH) at the University of Maryland, AADHum focuses both on critical digital humanities scholarship on the histories and impacts of digital technologies on Black lifeworlds, as well as on the ideation and production of projects that highlight how Black life bears on such technologies."
                }
            }
        },
        computed: {
            currentTabComponent() {
                const test = this.tabs[this.currentTab.name]
				return test.component
            }
        },
        methods: {
            mobileToggle() {
                const x = document.getElementById('mobileDisplay');
                if (x.style.display === "flex") {
                    x.style.display = "none";
                } else {
                    x.style.display = "flex";
                }
            },
            closeMenu() {
                const x = document.getElementById('hamburgerIcon');
                x.classList.toggle('active')
            
                this.mobileToggle()
            },
        }
    }
</script>

<style scoped lang="scss">

    .navContainer {
        display: grid;
        height: 5vh;
        min-height: 70px;
        margin-bottom: 40px;
        margin-top: -5px;
        background-color: #151515;
        grid-template-columns: 40% 60%;
        @media only screen and (max-width: 2000px) {
            grid-template-columns: 30% 70%;
        }
        @media only screen and (max-width: 1600px) {
            grid-template-columns: 20% 80%;
        }
        @media only screen and (max-width: 1350px) {
            grid-template-columns: 10% 90%;
        }
        @media only screen and (max-width: 1100px) {
            grid-template-columns: 100%;
            display: none;
        }
    }

    a {
        font-family: 'Alioli', fantasy;
        font-size: 26px;
        text-align: center;
        margin: 0;
        text-decoration: none;
        font-weight: bold;
        color: black;
    }

    #nav {
        display: flex;
        flex-direction: row;
        height: 5vh;
        min-height: 70px;
        @media only screen and (max-width: 1100px) {
            display: none;
        }
        a {
            padding: 0 16px;
          //&:last-child {
          //  padding: 0 0 0 16px;
          //}
        }
        
    }
    #newstab {
        background: #8245aa;
        color: #ffff73;
    }
    .tab-button {
        cursor: pointer;
        width: 100%;
        height: 100%;
        margin: 0;
        background: #F3B367;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    .tab-button:hover {
        background: #393939;
        color: #fff;
    }

    .tab-button.active {
        background: #cf5c5c;
        color: #fff !important;
    }

    .contentContainer {
        display: grid;
        grid-template-columns: 25% 75%;
        grid-gap: 60px;
        padding: 0 80px;
        margin: 0 auto;
        @media only screen and (max-width: 1500px) {
            grid-template-columns: 30% 70%;
        }
        @media only screen and (max-width: 1200px) {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
        }
    }

    .stickySide {
        position: sticky;
        top: 20vh;
        right: 50px;
        border: 1px solid rgba(128, 128, 128, 0.66);
        padding: 20px;
        box-shadow: 4px 8px 12px rgba(0, 0, 0, 0.25);
        
        @media only screen and (max-width: 800px) {
            position: relative;
            top: auto;
            right: auto;
            border: 0;
            box-shadow: 4px 8px 12px rgba(0, 0, 0, 0);
            padding: 0;
        }
    }

    .navContainerMobile {
        background-color: #F3B367;
        display: none;
        margin-top: -5px;
    
        .mobileMenuIcon {
            color: white;
            font-size: 24px;
            text-align: center;
        }
    
        .navLinksMobile {
            display: none;
            flex-direction: column;
            //grid-gap: 12px;
            width: 100%;
            margin: 0 auto;
            color: white;
        }
    
        a {
            font-weight: bold;
            cursor: pointer;
            font-size: 20px;
            margin: 0;
            padding: 6px 0;
        }
    
        @media only screen and (max-width: 1100px) {
            display: block;
        }
    }
    
    svg {
        transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .active svg {
        transform: rotate(90deg);
    }

    path {
        transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    path:nth-child(1) {
        transform-origin: 36% 40%;
    }

    path:nth-child(2) {
        stroke-dasharray: 29 299;
    }

    path:nth-child(3) {
        transform-origin: 35% 63%;
    }

    path:nth-child(4) {
        stroke-dasharray: 29 299;
    }

    path:nth-child(5) {
        transform-origin: 61% 52%;
    }

    path:nth-child(6) {
        transform-origin: 62% 52%;
    }

    .active path:nth-child(1) {
        transform: translateX(9px) translateY(1px) rotate(45deg);
    }

    .active path:nth-child(2) {
        stroke-dasharray: 225 299;
        stroke-dashoffset: -72px;
    }

    .active path:nth-child(3) {
        transform: translateX(9px) translateY(1px) rotate(-45deg);
    }

    .active path:nth-child(4) {
        stroke-dasharray: 225 299;
        stroke-dashoffset: -72px;
    }

    .active path:nth-child(5) {
        transform: translateX(9px) translateY(1px) rotate(-45deg);
    }

    .active path:nth-child(6) {
        transform: translateX(9px) translateY(1px) rotate(45deg);
    }
</style>