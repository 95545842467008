<template>
    <img class="AADHumToday" src="https://mouseion.aadhum.org/wl/?id=PfQbydQVAgDDDOjE3DfJpVk3jTYszzUo&fmode=open" alt="">
</template>

<script>
    export default {
        name: "AADHumToday"
    }
</script>

<style scoped lang="scss">
    .AADHumToday {
        width: 100vw;
        max-width: 100%;
        height: 93vh;
        object-fit: cover;
    }
</style>