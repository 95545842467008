<template>
    <div id="About" class="aboutPage">
        <h2>News & Updates</h2>
        <div id="blog-post"></div>
        <h3><span>Check out the <a style="color: #8245aa" href="/news" target="_blank">News</a> tab to see more of our latest posts!</span></h3>
    </div>
</template>

<script>
    export default {
        name: "About",
        created() {
            fetch('https://aadhum-news.ghost.io/ghost/api/content/posts/?key=b8dc8d6f811da282c95619d4e9&fields=title,url,feature_image,published_at,excerpt&limit=3')
                .then(response => response.json())
                .then(result => {
                    let posts = result['posts']
                    let postHTML = '<style> .postContent { @media only screen and (max-width: 1200px) { flex-direction: column } }</style>'
                    for (var i = 0; i < posts.length; i++) {
                        postHTML +=
                            `<a href="${posts[i].url}" target="_blank" class="postContent" style="display: flex; padding-bottom: 40px; grid-gap: 20px; ">
                                <img src="${posts[i].feature_image}" style="max-width: 400px; object-fit: contain">
                                <div>
                                                                <p style="font-size: 30px; font-weight: bold; margin: 10px 0 20px 0;">${posts[i].title}</p>
                                <p style="font-size: 24px; color: black;">${posts[i].excerpt}</p>
                                
</div>

                            </a>`
                    }
                    document.getElementById('blog-post').innerHTML = postHTML
                })
        }
    }
</script>

<style scoped lang="scss">
    li {
        font-size: 24px;
        font-family: "freight-text-pro", serif;
    }
    
    .title {
        margin-top: 0;
    }
    
    .aboutPage {
        max-width: 1400px;
        margin: 0 auto;
    }
    h3 {
        text-align: center;
        margin-bottom: 50px;
        span {
            background-color: #ffe4d7;
            padding: 16px 24px;
        }
    }
    
    .announcement {
        display: flex;
        flex-direction: row;
        padding: 0 40px 20px 20px;
        grid-gap: 40px;
        margin-bottom: 40px;
        border-left: 10px solid #cf5c5c;
        
        h2, h3 {
            margin: 0;
        }
        
        .announcement-image {
            width: 60%;
            max-width: 450px;
            max-height: 450px;
            height: fit-content;
            border-radius: 25px;
            box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.35);
            @media only screen and (max-width: 800px) {
                width: 100%;
            }
        }
        
        @media only screen and (max-width: 800px) {
            flex-direction: column;
        }
        
    }
    
    .filter {
        display: flex;
        
        h3 {
            margin-right: 48px;
        }
        
        .filterButton {
            cursor: pointer;
            font-weight: normal;
            font-size: 28px;
            color: #8f8f8f;
            border: 2px solid #8f8f8f;
            padding: 4px 8px;
        }
        
        .activeBtn {
            color: black;
            border: 2px solid black;
            
        }
    }
</style>