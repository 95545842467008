<template>
<div id="Community" class="communityPage">
    <CommunityBlock group="Social Media Corps Summer 2024" jsontag="community/SMC24.json"></CommunityBlock>
    <CommunityBlock group="Kitchen Studio Grants 2023-2024" jsontag="community/kitchenstudios2324.json"></CommunityBlock>
    <CommunityBlock group="Scholars Microgrants 2023-2024" jsontag="community/microgrants2324.json"></CommunityBlock>
    <CommunityBlock group="The AADHum Scholars 2022-2023" jsontag="community/tasp22.json"></CommunityBlock>
    <CommunityBlock group="AADHum Residencies 2022-2023" jsontag="community/residency22.json"></CommunityBlock>
    <CommunityBlock group="The Graduate AADHum Scholars 2022-2023" jsontag="community/tgasp22.json"></CommunityBlock>
    <CommunityBlock group="Social Media Corps Summer 2022" jsontag="community/SMC22.json"></CommunityBlock>
    <CommunityBlock group="Social Media Corps Summer 2021" jsontag="community/SMC21.json"></CommunityBlock>
    <CommunityBlock group="AADHum Scholars 2019-2020" jsontag="community/scholars1920.json"></CommunityBlock>
    <CommunityBlock group="AADHum Scholars 2018-2019" jsontag="community/scholars1819.json"></CommunityBlock>
    <CommunityBlock group="AADHum Scholars 2017-2018" jsontag="community/scholars1718.json"></CommunityBlock>
</div>
</template>

<script>
    import CommunityBlock from "../Community/CommunityBlock";
    export default {
        name: "Community",
        components: {CommunityBlock}
    }
</script>

<style scoped lang="scss">
    .communityPage {
        max-width: 1400px;
        margin: 0 auto;
    }
</style>