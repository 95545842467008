<template>
    <transition name="modal">
        <div class="modal-mask" >
            <div class="modal-wrapper" >
                <div class="modal-container">
                    <div class="closeContainer2">
                        <span class="closeBtn2" @click="$emit('closeModal')">X</span>
                    </div>
                    <div class="top">
                        <img :src="person.profileImage" :alt="person.displayName" class="profilePic">
                        <div class="nameInfo">
                            <h2 class="name">{{person.displayName}}</h2>
                            <h3 class="title" v-html="person.title"></h3>
                            <a :href="person.website" v-if="person.website" class="websiteLink" target="_blank">{{person.website}}</a>
                            <br v-if="person.website" />
                            <a :href="person.twitter" v-if="person.twitter" target="_blank">
                                <img src="https://mouseion.aadhum.org/wl/?id=mBHMMJT5kp598ABNrnR6PdVZ38WOy6So&fmode=open" alt="Twitter Icon" class="twitterIcon">
                            </a>
                        </div>
                    </div>
                    <p class="bio" v-html="person.bio"></p>
                    <div class="closeContainer">
                        <span class="closeBtn" @click="$emit('closeModal')">Close</span>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "Person",
        props: ['person'],
        emits: ['closeModal']
    }
</script>

<style scoped lang="scss">
    .top {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 40px;
        @media only screen and (max-width: 1000px) {
            grid-template-columns: 1fr;
            grid-gap: 0;
        }
    }

    .nameInfo {
        @media only screen and (max-width: 1000px) {
            text-align: center;
        }
    }
    .profilePic {
        width: 250px;
        height: 250px;
        object-fit: cover;
        @media only screen and (max-width: 1000px) {
            margin: 0 auto;
        }
    }
    .name {
        margin-bottom: 0;
    }
    .title {
        font-weight: normal;
        // font-style: italic;
        font-size: 24px;
        margin: -2px 0 4px 0;
    }
    .websiteLink {
        text-decoration: none;
        font-weight: bold;
        color: #cf5c5c;
        transition: .25s;

        &:hover {
            color: #F3B367;
            transition: .25s;

        }
    }
    .twitterIcon {
        width: 50px;
        height: 50px;
        margin-top: 20px;
        filter: grayscale(100%);
        transition: .5s;
        &:hover {
            filter: grayscale(0%);
            transition: .5s;
        }
    }

    .bio {
        line-height: 1.25;
    }
    .closeContainer {
        display: flex;
        justify-content: center;
    }
    .closeContainer2 {
        display: flex;
        justify-content: right;
    }
    .closeBtn {
        background-color: #cf5c5c;
        color: white;
        font-size: 20px;
        padding: 12px;
        cursor: pointer;
        transition: .5s;
        &:hover {
            background-color: #F3B367;
            color: #404040;
            transition: .5s;
        }
    }
    .closeBtn2 {
        background-color: #cf5c5c;
        color: white;
        font-size: 24px;
        padding: 12px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: .5s;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        &:hover {
            background-color: #944747;
            color: #ffd881;
            transition: .5s;
        }
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 800px;
        max-height: 80vh;
        overflow-y: auto;
        margin: 0 auto;
        padding: 40px;
        background-color: #fff;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.35);
        transition: all 0.3s ease;
        @media only screen and (max-width: 800px) {
            width: 85%;
            padding: 12px;
        }
    }

</style>