<template>
    <div class="footerContainer">
        <div id="footer">
            <div class="logoContainer">
                <img class="logos" src="https://mouseion.aadhum.org/wl/?id=txSbbgMGzs2CLf7PggqXLwWt3K1lXCgD&fmode=open" alt="University of Maryland College of Arts and Humanities Logo">
            </div>
            <div class="logoContainer">
                <img class="logos" src="https://mouseion.aadhum.org/wl/?id=lHTG9fxxff53DY3uyFuQrNPo4oQJ20YY&fmode=open" alt="Mellon Foundation Logo">
            </div>
            <div class="logoContainer">
                <img class="logos" src="https://mouseion.aadhum.org/wl/?id=X6Qh7i3GAbAmXy7p5HQYbgH9t19XbEEi&fmode=open" alt="Maryland Institute for Technology in the Humanities Logo">
            </div>
            <div class="textContainer">
                <p class="textInfo">Header Photo from
                    <a href="https://irlh.org/som/" target="_blank">Project Son of Man</a></p>
                <p class="textInfo">Site Design by <a href="https://mp285.com/" target="_blank">Marisa Parham</a> and <a href="https://awwsmith.com/" target="_blank">Andrew W. Smith</a></p>
                <p class="textInfo">Hosted by the <a href="https://irlhumanities.org/" target="_blank">Immersive Realities Labs for the Humanities</a></p>
                <p class="textInfo">Copyright © 2025 All Rights Reserved</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped lang="scss">
    a {
        color: #cf5c5c;
		&:hover {
			color: #F3B367;
		}
    }
    .footerContainer {
        min-height: 200px;
        width: 100%;
        background-color: #000;
        align-items: center;
        display: flex;
        margin-top: 80px;
        padding: 20px 0;
    }

    #footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-gap: 40px;
        justify-content: space-evenly;
        align-items: center;
        @media only screen and (max-width: 1140px) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .logos {
        width: 250px;
        margin: 0 auto;
    }

    .textInfo {
        color: #8f8f8f;
        text-align: right;
        font-size: 16px;
        @media only screen and (max-width: 800px) {
            text-align: center;
        }
    }
</style>