<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="closeContainer2">
                        <span class="closeBtn2" @click="$emit('closeModal')">X</span>
                    </div>
                    <div class="modalContentContainer">
                        <div class="top">
                            <img :src="project.image" alt="" class="featuredImage">
                            <div class="nameInfo">
                                <h2 class="name">{{project.projectTitle}}</h2>
                                <h3 class="subhead">Leads:</h3>
                                <h3 class="lead">{{ project.leads }}</h3>
                                <h3 class="subhead" v-if="project.collaborators">AADHum Collaborators</h3>
                                <h3 class="lead" v-if="project.collaborators">{{ project.collaborators }}</h3>
                                <div class="statuses">
                                    <span v-for="status in project.status" :key="status" id="status" :class="status[1]">{{ status[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <p v-if="project.description" class="description" v-html="project.description"></p>
                        <p class="learnMoreLink" v-if="project.link">Learn more at: <a :href="project.link" v-if="project.link" class="websiteLink" target="_blank">{{project.link}}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "Project",
        props: ['project'],
        emits: ['closeModal']
    }
</script>

<style scoped lang="scss">
    .top {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
    }
    .featuredImage {
        max-width: 100%;
        max-height: 400px;
        object-fit: contain;
        margin: 0 auto;
        // justify-self: flex-start;
        @media only screen and (max-width: 800px) {
            max-width: 90%;
            max-height: 250px;
        }
    }

    .closeContainer {
        display: flex;
        justify-content: center;

    }

    .closeContainer2 {
        display: flex;
        justify-content: right;
        border-bottom: 1px solid #cf5c5c;
        background-color: #ffd881;
        position: fixed;
        width: 800px;
        @media only screen and (max-width: 800px) {
            width: 85%;
        }
    }
    
    .closeBtn {
        background-color: #cf5c5c;
        color: white;
        font-size: 20px;
        padding: 12px;
        cursor: pointer;
        transition: .5s;
        &:hover {
            background-color: #F3B367;
            color: #404040;
            transition: .5s;
        }
    }

    .closeBtn2 {
        background-color: #cf5c5c;
        color: white;
        font-size: 20px;
        padding: 6px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: .5s;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        &:hover {
            background-color: #944747;
            color: #ffd881;
            transition: .5s;
        }
    }
    
    .modalContentContainer {
        margin-top: 20px;
        padding: 30px;
    }
    
    .nameInfo {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-top: 20px;
    }
    
    .name {
        display: flex;
        flex-direction: row;
        grid-gap: 20px;
        align-items: center;
        margin: 8px 0;
        @media only screen and (max-width: 800px) {
            font-size: 26px;
        }
    }
    
    .subhead {
        font-size: 14px;
        text-transform: uppercase;
        margin: 0;
    }
    .lead {
        display: flex;
        flex-direction: row;
        font-family: 'freight-text-pro', serif;
        margin: 0;
        font-size: 22px;
        padding-bottom: 10px;
        font-weight: normal;
    }
    .statuses {
        display: flex;
        flex-wrap: wrap;
    }
    
    #status {
        padding: 4px 8px;
        border-radius: 12px;
        font-size: 13px;
        font-weight: bold;
        color: black;
        margin: 4px 8px 4px 0;
        white-space: nowrap;
        &.available {
            background-color: #98ffa9;
        }
        &.development {
            background-color: #fffe96;
        }
        &.collab {
            background-color: #98ffcf;
        }
        &.extension {
            background-color: #ccb7ff;
        }
    }

    .learnMoreLink {
        font-size: 18px;
        margin: 12px 0;
        font-weight: bold;
    }

    .description {
        border-top: 1px solid black;
        margin: 12px 0;
        padding-top: 20px;
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 800px;
        max-height: 80vh;
        overflow-y: auto;
        margin: 0 auto;
        background-color: #fff;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.35);
        transition: all 0.3s ease;
        @media only screen and (max-width: 800px) {
            width: 85%;
            padding: 12px;
        }
    }
</style>