<template>
    <h2>{{ group }}</h2>
    <div class="block">
        <div v-for="person in people" :key="person.position" class="personBox">
            <img :alt="person.name" :src="person.image" class="photo">
            <p class="name">{{ person.name }}</p>
            <p class="title">{{ person.title }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CommunityBlock",
        props: ['group', 'jsontag'],
        data() {
            return {
                people: []
            }
        },
        created() {
            let jsonkey = this.jsontag
            
            const request = new XMLHttpRequest();
            request.open("GET", jsonkey, false);
            request.send(null)
            this.people = (JSON.parse(request.responseText));
            
            this.people = this.people.sort((a, b) => {
                let fa = a.name,
                    fb = b.name
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })
            this.people.forEach(i => {
                console.log( i.title)
                if (i.title !== undefined) {
                    this.people.push(this.people.splice(this.people.indexOf(i), 1)[0]);
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
    h2 {
        font-family: Avera Sans TC, sans-serif;
        font-weight: normal;
        margin-bottom: 6px;
    }
    
    .block {
        display: flex;
        grid-gap: 20px;
        flex-wrap: wrap;
        padding-bottom: 10px;
        
        .photo {
            width: 125px;
            height: 125px;
            object-fit: cover;
            object-position: center;
            border-radius: 100px;
        }
        
        .name {
            font-size: 18px;
            margin: 0;
            width: 125px;
            text-align: center;
        }
        .title {
            font-size: 15px;
            margin: 0;
            width: 125px;
            text-align: center;
            font-weight: bold;
        }
    }
</style>