<template>
    <div id="Team">
        <div class="peopleGrid">
            <div v-for="person in people" :key="person.position" class="personBox"
                 @click="currentPerson = person; showModal = true">
                <img :src="person.profileImage" :alt="person.displayName" class="profileImage">
                <h2 class="name">{{ person.displayName }}</h2>
                <h3 class="title" v-html="person.title"></h3>
                <p class="learnMore">Click for more</p>
            </div>
            <transition name="modal">
                <Person :person="currentPerson" v-if="showModal" v-on:closeModal="showModal=false"/>
            </transition>
        </div>
    </div>
</template>

<script>
    import Person from "../People/Person";

    export default {
        name: "Team",
        components: {Person},
        data() {
            return {
                people: [],
                showModal: false,
                currentPerson: null
            }
        },
        created() {
            const request = new XMLHttpRequest();
            request.open("GET", "AADHumPeople.json", false);
            request.send(null)
            this.people = (JSON.parse(request.responseText));

            this.people = this.people.sort((a, b) => {
                let fa = a.order,
                    fb = b.order
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })
        }
    }
</script>

<style scoped lang="scss">
    #Team {
        max-width: 1400px;
        margin: 0 auto;
    }

    .peopleGrid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-gap: 60px;
        @media only screen and (max-width: 800px) {
            justify-content: center;
        }
    }

    .personBox {
        width: 300px;
        cursor: pointer;

        @media only screen and (max-width: 1300px) {
            width: 275px;
        }
		&:hover .learnMore {
			color: #cf5c5c;
            text-decoration: underline;
		}
        &:hover .profileImage {
            transition: .25s;
            filter: grayscale(0);
            transform: translate(4px, -4px);
            box-shadow: -8px 8px 0 #F3B367;
        }
    }

    .profileImage {
        width: 300px;
        height: 300px;
        object-fit: cover;
		// filter: grayscale(1);
		transition: .5s;
        @media only screen and (max-width: 1300px) {
            width: 275px;
            height: 275px;
        }
    }

    .name {
        margin: 8px 0 0 0;
        font-size: 32px;
        line-height: 1;
    }

    .title {
        font-weight: normal;
        font-size: 18px;
        margin: 8px 0;
        // font-style: italic;
        font-family: 'freight-serif-pro', serif;
    }

    .more {
        font-size: 14px;
        color: #cf5c5c;
        font-weight: bold;
        margin: 0;
    }

    .learnMore {
        font-family: 'freight-sans-pro', sans-serif;
        margin: 0;
    }
</style>