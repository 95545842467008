<template>
    <div id="Research">
        <div class="filter">
            <h3 id="projectsBtn" class="filterButton" v-on:click="filterSelection('projects')">Digital Projects</h3>
            <h3 id="publicationsBtn" class="filterButton" v-on:click="filterSelection('publications')">Publications</h3>
        </div>
        <div v-if="showProjects" class="grid">
            <div v-for="project in projects" :key="project.projectTitle" :class="project.category"
                 class="researchBox projectFilter" @click="currentProject = project; showModal = true">
                <div>
                    <img :src="project.image" alt="" class="featuredImage">
                    <h2 class="title">{{ project.projectTitle }}</h2>
                    <h3 class="subhead">Leads:</h3>
                    <h3 class="leads">{{ project.leads }}</h3>
                    <h3 class="subhead" v-if="project.collaborators">AADHum Collaborators</h3>
                    <h3 class="leads" v-if="project.collaborators">{{ project.collaborators }}</h3>
                    <br/>
                    <div class="statuses">
                        <span v-for="status in project.status" :key="status" id="status" :class="status[1]">{{ status[0]
                            }}</span>
                    </div>
                </div>
            </div>
        </div>
        <transition name="modal">
            <Project v-if="showModal" :project="currentProject" v-on:closeModal="showModal=false"/>
        </transition>
        
        <div v-if="!showProjects" class="grid">
            <div v-for="publication in publications" :key="publication.projectTitle" :class="publication.category"
                 class="researchBox projectFilter">
                <a :href="publication.link" class="spacer" target="_blank">
                    <div class="infoContainer">
                        <div>
                            <img :src="publication.image" alt="" class="featuredImage">
                            <h2 class="title">{{ publication.projectTitle }}</h2>
                            <h3 class="leads">{{ publication.leads }}</h3>
                        </div>
                        <div class="pubContainer">
                            <p v-if="publication.publishedDate" :class="publication.statusClass" class="pubStatus">
                                {{ publication.status }} in <span class="pubName">{{ publication.Publication }}</span>
                                <br/>
                                {{ publication.publishedDate }}
                            </p>
                            <p v-if="!publication.publishedDate" class="pubStatus development status unreleased">
                                Unreleased</p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import Project from "../Research/Project";
    
    export default {
        name: "Research",
        components: {Project},
        data() {
            return {
                showProjects: true,
                projects: [],
                publications: [],
                showModal: false,
                currentProject: null
            }
        },
        methods: {
            filterSelection(c) {
                const projectsBtn = document.getElementById('projectsBtn')
                const publicationsBtn = document.getElementById('publicationsBtn')
                
                if (c === 'projects') {
                    this.showProjects = true
                    publicationsBtn.classList.remove('activeBtn')
                    projectsBtn.classList.add('activeBtn')
                } else if (c === 'publications') {
                    this.showProjects = false
                    projectsBtn.classList.remove('activeBtn')
                    publicationsBtn.classList.add('activeBtn')
                }
                
            },
        },
        created() {
            const request = new XMLHttpRequest();
            request.open("GET", "Research-Projects.json", false);
            request.send(null)
            this.projects = (JSON.parse(request.responseText));
            
            this.projects = this.projects.sort((a, b) => {
                let fa = a.projectTitle,
                    fb = b.projectTitle
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })
            
            const request2 = new XMLHttpRequest();
            request2.open("GET", "Research-Publications.json", false);
            request2.send(null)
            this.publications = (JSON.parse(request2.responseText));
            
            this.publications = this.publications.sort((a, b) => {
                let fa = a.date,
                    fb = b.date
                if (fa < fb) {
                    return 1;
                }
                if (fa > fb) {
                    return -1;
                }
                return 0;
            })
        },
        mounted() {
            this.filterSelection('projects')
        }
    }
</script>

<style lang="scss" scoped>
    #Research {
        max-width: 1400px;
        margin: 0 auto;
    }
    
    .sectionTitle {
        padding-top: 40px;
        margin: 0;
    }
    
    .filter {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 1000px) {
            flex-direction: column;
            // justify-items: center;
        }
        
        .filterButton {
            cursor: pointer;
            font-weight: normal;
            font-size: 32px;
            color: #8f8f8f;
            border: 2px solid #8f8f8f;
            padding: 12px 8px 8px 8px;
            margin-right: 24px;
            transition: 0.25s;
            
            font-family: Avera Sans TC, sans-serif;
            
            &:hover {
                border: 2px solid #cf5c5c;
                transition: 0.25s;
            }
            
            @media only screen and (max-width: 800px) {
                text-align: center;
                justify-content: center;
                margin: 12px auto;
                padding: 8px;
            }
        }
        
        .activeBtn {
            color: black;
            border: 2px solid black;
        }
    }
    
    .grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // justify-content: space-between;
        grid-gap: 30px;
    
        @media only screen and (max-width: 800px) {
            justify-content: center;
        }
        //@media only screen and (max-width: 750px) {
        //	justify-items: center;
        //}
        
        &::after {
            content: "";
            width: 365px;
            @media only screen and (max-width: 1300px) {
                width: 315px;
            }
        }
    }
    
    .researchBox {
        width: 380px;
        background-color: rgb(238, 238, 238);
        padding: 18px;
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        cursor: pointer;
        border: 2px solid white;
        transition: 1s;
        
        &.publications {
            padding-bottom: 10px;
        }
        
        a {
            color: black;
        }
        
        &:hover {
            border: 2px solid #2d2d2d;
            transition: .75s;
            transform: translateY(-15px);
            background-color: #e1faff;
        }
        
        @media only screen and (max-width: 1300px) {
            width: 300px;
        }
        @media only screen and (max-width: 1850px) {
            width: 350px;
        }
        
    }
    
    .spacer {
        height: 100%;
    }
    
    .featuredImage {
        width: 380px;
        height: 220px;
        object-fit: cover;
        @media only screen and (max-width: 1300px) {
            width: 300px;
        }
        @media only screen and (max-width: 1850px) {
            width: 350px;
        }
    }
    
    .title {
        margin: 4px 0 0 0;
        // font-size: clamp(16px, 28px, 32px);
        max-height: 70px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 26px;
        line-height: 1.2;
        object-fit: cover;
        @media only screen and (max-width: 1850px) {
            font-size: 24px;
        }
    }
    
    .leads {
        margin: 8px 0 0 0;
        font-weight: normal;
        font-family: 'freight-serif-pro', serif;
        font-size: 22px;
        @media only screen and (max-width: 1850px) {
            font-size: 20px;
        }
    }
    
    .subhead {
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    
    .statuses {
        display: flex;
        flex-wrap: wrap;
    }
    
    #status {
        padding: 4px 8px;
        border-radius: 12px;
        font-size: 13px;
        font-weight: bold;
        color: black;
        margin: 4px;
        white-space: nowrap;
        
        &.pubStatus {
            border-radius: 0;
        }
    }
    
    .development {
        background-color: #fffe96;
    }
    
    .available {
        background-color: #98ffa9;
    }
    
    .collab {
        background-color: #98fff3;
    }
    
    .extension {
        background-color: #ccb7ff;
    }
    
    .infoContainer {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    
    .pubContainer {
    
    }
    
    .pubStatus {
        font-size: 16px;
        margin: 12px 0 0 0;
        padding: 4px 0;
        text-align: center;
        line-height: 1;
    }
    
    .pubName {
        font-size: 14px;
        font-weight: bold;
        font-family: 'freight-sans-pro', sans-serif;
    }
    
    .unreleased {
        background-color: #b7b7b7;
    }
</style>