import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'

router.beforeEach((to, from, next) => {
    if (to.path === '/news') {
        window.location.href = 'https://aadhum-news.ghost.io/'
    } else {
        next()
    }
})

createApp(App).use(router).mount('#app')
