<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
                    <div class="closeContainer2">
                        <span class="closeBtn2" @click="$emit('closeModal')">X</span>
                    </div>
					<img :src="program.image" alt="" class="featuredImage">
					<h2>{{ program.name }}</h2>
					<div v-html="program.description"></div>
					<div class="closeContainer">
						<span class="closeBtn" @click="$emit('closeModal')">Close</span>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		name: "Program",
		props: ['program'],
		emits: ['closeModal']
	}
</script>

<style lang="scss" scoped>
	
	.featuredImage {
		width: 400px;
        @media only screen and (max-width: 800px) {
            width: 300px;
        }
	}
    
    h2 {
        @media only screen and (max-width: 800px) {
            font-size: 32px;
        }
    }
	
	.closeContainer {
		display: flex;
		justify-content: center;
	}
	
	.closeBtn {
		background-color: #cf5c5c;
		color: white;
		font-size: 20px;
		padding: 12px;
		cursor: pointer;
		transition: .5s;
		
		&:hover {
			background-color: #F3B367;
			color: #404040;
			transition: .5s;
		}
	}
	
	.modal-mask {
		position: fixed;
		z-index: 9998;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
		display: table;
		transition: opacity 0.3s ease;
	}
	
	.modal-wrapper {
		display: table-cell;
		vertical-align: middle;
	}
	
	.modal-container {
		width: 800px;
		max-height: 80vh;
		overflow-y: auto;
		margin: 0 auto;
		padding: 40px;
		background-color: #fff;
		box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.35);
		transition: all 0.3s ease;
        @media only screen and (max-width: 800px) {
            width: 85%;
            padding: 12px;
        }
	}
    
    .closeContainer2 {
        display: flex;
        justify-content: right;
    }
    .closeBtn2 {
        background-color: #cf5c5c;
        color: white;
        font-size: 24px;
        padding: 12px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: .5s;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        &:hover {
            background-color: #944747;
            color: #ffd881;
            transition: .5s;
        }
    }
</style>